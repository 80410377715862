<mat-tab-group
  mat-stretch-tabs="false"
  [selectedIndex]="selectedIndex()"
  [animationDuration]="animationDuration()"
  [preserveContent]="true"
  (selectedIndexChange)="onSelectedIndexChange($event)"
  libTabActivated
>
  @for (tab of tabs(); track tab.tabId) {
    <mat-tab [label]="tab.label" style="pointer-events: auto">
      <ng-template mat-tab-label>
        <div class="flex-row align-center gap-sm" (auxclick)="removeTab($index, $event)" (contextmenu)="onTabContextMenu($event, $index, tab)">
          <span style="text-align: center">{{ tab.label }}</span>
          <button (click)="removeTab($index,$event); $event.stopPropagation();" mat-icon-button>
            <mat-icon [svgIcon]="EsvgFiles.close"></mat-icon>
          </button>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <lib-tab-content [data]="tab"/>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
<button
  [style.left.px]="position().x"
  [style.top.px]="position().y"
  style="position: fixed; visibility: hidden;" [matMenuTriggerFor]="menu"></button>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="closeAllTabs()">Alle Tabs schließen</button>
  <button mat-menu-item (click)="closeAllTabsExcept()">Andere Tabs schließen</button>
</mat-menu>
