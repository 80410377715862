import {Component, inject, signal, viewChild} from '@angular/core';
import {MatTab, MatTabContent, MatTabGroup, MatTabLabel} from '@angular/material/tabs';
import {TabContentComponent} from './tab/tab-content.component';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {EsvgFiles} from 'frontier/nucleus';
import {TabsService} from '../tabs/tabs.service';
import {ITab} from 'frontier/browserkit/src/lib/components/home-without-gl/tabs/tab.interface';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {TabActivatedDirective} from 'frontier/browserkit/src/lib/directives/tab-activated.directive';

@Component({
  selector: 'lib-tabs',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    TabContentComponent,
    MatTabLabel,
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatButton,
    TabActivatedDirective
  ],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  protected readonly EsvgFiles = EsvgFiles;
  private _tabsService = inject(TabsService);
  private _menuTrigger = viewChild(MatMenuTrigger);

  selectedIndex = this._tabsService.selectedTabIndex;
  tabs = this._tabsService.tabs;
  animationDuration = this._tabsService.animationDuration;
  position = signal({
    x: 0,
    y: 0
  });
  menuTab = signal<ITab | null>(null);

  removeTab(index: number, evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    this._tabsService.closeTabByIndex(index);
  }

  onTabContextMenu(evt: MouseEvent, index: number, tab: ITab) {
    evt.stopPropagation();
    evt.preventDefault();
    this.menuTab.set(tab);
    this.position.set({x: evt.clientX, y: evt.clientY});
    this._menuTrigger().openMenu();
  }

  closeAllTabs() {
    this._tabsService.closeAllTabs();
  }

  closeAllTabsExcept() {
    this._tabsService.closeAllTabsExcept(this.menuTab());
  }

  onSelectedIndexChange(idx: number) {
    this._tabsService.selectTabByIndex(idx);
  }
}
