import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import {EControlActions, IApiAdapter, IApiControl} from 'frontier/nucleus';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {FormWrapperControlComponent} from './form-wrapper-control/form-wrapper-control.component';
import {SelectElementComponent} from '../form-element/select-element/select-element.component';
import {NgIf} from '@angular/common';
import {DefaultFormApiAdapter} from 'frontier/browserkit';
import {FormRepositorySelectionComponent} from './form-repository-selection/form-repository-selection.component';

@Component({
  selector: 'kpi4me-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    SelectElementComponent,
    FormWrapperControlComponent,
    FormRepositorySelectionComponent,
  ],
})
export class FormWrapperComponent implements OnDestroy {
  formWrapperControl = viewChild(FormWrapperControlComponent);
  formRepositorySelection = viewChild(FormRepositorySelectionComponent);

  selectFormControl = new FormControl(null);

  @Input() GUID: string;
  @Input() deleteInSelectionAlwaysEnabled = true;
  @Input() deleteInSelectionEnabled = true;
  @Input() createNewEnabled = true;
  @Input() apiAdapter: IApiAdapter = new DefaultFormApiAdapter();
  @Input() visibleFormFields = true;
  @Input() deleteEnabled = true;
  @Input() displayHeaders: boolean = true;
  @Input() selectionLabel: string;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() getFormData = new EventEmitter<any>();
  @Output() formChange = new EventEmitter();

  apiInstance: IApiControl;

  formInit = new EventEmitter();
  subs = new Subscription();

  protected readonly EControlActions = EControlActions;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    this.subs.add(
      this.selectFormControl.valueChanges.subscribe(v => {
        this.selectionChange.emit(v);
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
