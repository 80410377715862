import {contentChildren, Directive, HostListener} from '@angular/core';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {TableControlComponent} from "frontier";
import {combineLatest, Subject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Directive({
  selector: '[libTabActivated]',
  standalone: true,
})
export class TabActivatedDirective {
  components = contentChildren(TableControlComponent, {descendants: true});
  tabChanged$ = new Subject<MatTabChangeEvent>();
  private tables = new Set<TableControlComponent>();

  constructor() {
    combineLatest([
      this.tabChanged$,
    ]).pipe(
      takeUntilDestroyed()
    )
      .subscribe(() => {
        this.tables.forEach((t) => t.onTabActivated());
      })
  }

  registerTable(table: TableControlComponent): void {
    this.tables.add(table);
  }

  unregisterTable(table: TableControlComponent): void {
    this.tables.delete(table);
  }

  @HostListener('selectedTabChange', ['$event'])
  onTabChange(event: MatTabChangeEvent): void {
    this.tabChanged$.next(event);
  }
}
