import AppControlGuids from '../api/ControlGuids';
import LibControlGuids from "frontier/nucleus/src/lib/apiv2/ControlGuids";

export enum ProtectedView {
  ProcessOverviewComponent = 'ProcessOverviewComponent',
  MissionDataCollectionComponent  = 'MissionDataCollectionComponent',
  PayerOverviewComponent = 'PayerOverviewComponent',
  TariffChangesComponent = 'TariffChangesComponent',
  RescueStationComponent = 'RescueStationComponent',
  TemplateEditorComponent = 'TemplateEditorComponent',
  DunningConfigComponent = 'DunningConfigComponent',
  DtaServiceConflictTableComponent = 'DtaServiceConflictTableComponent',
  ConfigurationOverviewComponent = 'ConfigurationOverviewComponent',
  OnboardingOverviewComponent = 'OnboardingOverviewComponent',
  ItemTableComponent = 'ItemTableComponent',
  ItemGroupBaseTableComponent = 'ItemGroupBaseTableComponent',
  PayerTableComponent = 'PayerTableComponent',
  DataOfficeBaseTableComponent = 'DataOfficeBaseTableComponent',
  InvoiceTemplateTableComponent = 'InvoiceTemplateTableComponent',
  CollectiveinvoiceTableComponent = 'CollectiveinvoiceTableComponent',
  CarttypeTableComponent = 'CarttypeTableComponent',
  AddressinfoTableComponent = 'AddressinfoTableComponent',
  BusinessAreasTableComponent = 'BusinessAreasTableComponent',
  ItemPriceMappingTableComponent = 'ItemPriceMappingTableComponent',
  InvoiceTemplateConfigFormComponent = 'InvoiceTemplateConfigFormComponent',
  GlobalTemplateConfigFormComponent = 'GlobalTemplateConfigFormComponent',
  BaseInvoiceTemplateConfigTableComponent = 'BaseInvoiceTemplateConfigTableComponent',
  ConspicuityDetailComponent = 'ConspicuityDetailComponent',
  ClientDetailComponent = 'ClientDetailComponent',
  TransportDetailComponent = 'TransportDetailComponent',
  CrewDetailComponent = 'CrewDetailComponent',
  CartAdditionalDetailComponent = 'CartAdditionalDetailComponent',
  InvoiceDetailComponent = 'InvoiceDetailComponent',
  TrapoDetailComponent = 'TrapoDetailComponent',
  InternalNoteDetailComponent = 'InternalNoteDetailComponent',
  PermanentOrdinanceComponent = 'PermanentOrdinanceComponent',
  PayerHiddenTableComponent = 'PayerHiddenTableComponent',
  DataofficeTableComponent = 'DataofficeTableComponent',
  AlternativePayerTableComponent = 'AlternativePayerTableComponent',
  CustomerBasedataConfigFormComponent = 'CustomerBasedataConfigFormComponent',
  BookingOptionsConfigFormComponent = 'BookingOptionsConfigFormComponent',
  OpenItemImportConfigFormComponent = 'OpenItemImportConfigFormComponent',
  EpostConfigWrapperComponent = 'EpostConfigWrapperComponent',
  VerificationRulesConfigFormComponent = 'VerificationRulesConfigFormComponent',
  UserListComponent = 'UserListComponent',
  BillingEvaluations = 'BillingEvaluationsComponent',
  MailTemplateComponent = 'MailTemplateComponent',
  SmtpConfigurationComponent = 'SmtpConfigurationComponent',
}

/**
 * Map that contains for each key a set of guids that need
 * to be authorized to access the corresponding component.
 * At least one of the guids in the set must be present in the
 * user's roles to access the component.
 */
export const AUTHORIZATION_CONFIG: Record<ProtectedView, string[]> = {
  // --- SIDE NAVIGATION -------
  [ProtectedView.ProcessOverviewComponent]: [
    AppControlGuids.TFullCartStatus,
    AppControlGuids.TAllClientList,
    AppControlGuids.TOutboxBook,
    AppControlGuids.TOpenItemList,
    AppControlGuids.TCartList,
  ],
  [ProtectedView.MissionDataCollectionComponent]: [
    AppControlGuids.TTransportList,
    AppControlGuids.TNotBillable,
    AppControlGuids.TAllClientList,
    AppControlGuids.TCartList,
    AppControlGuids.TCrewList,
    AppControlGuids.TOrdinanceList,
    AppControlGuids.TClientList,
  ],
  [ProtectedView.PayerOverviewComponent]: [
    AppControlGuids.TPayerTableView,
    AppControlGuids.TAlternativePayerView,
    AppControlGuids.TDataOfficeTableView,
  ],
  [ProtectedView.BillingEvaluations]: [
    AppControlGuids.TInvoiceExport,
    AppControlGuids.TTransportExport,
  ],
  [ProtectedView.TariffChangesComponent]: [AppControlGuids.TItemPriceHistoryView],
  [ProtectedView.RescueStationComponent]: [AppControlGuids.TStationTableView],
  [ProtectedView.TemplateEditorComponent]: [AppControlGuids.TTemplateConfigurationControl],
  [ProtectedView.DunningConfigComponent]: [AppControlGuids.TDunningConfigView, AppControlGuids.TDunningLevelView],
  [ProtectedView.DtaServiceConflictTableComponent]: [AppControlGuids.TDTAServiceConfigView],
  [ProtectedView.UserListComponent]: [LibControlGuids.TUserListTable],
  [ProtectedView.ConfigurationOverviewComponent]: [
    AppControlGuids.TEpostConfiguration,
    AppControlGuids.TCustomerBasedataConfiguration,
    AppControlGuids.TBookingOptionsConfig,
    AppControlGuids.TOpenItemImportConfig,
    AppControlGuids.TTestingSettingsConfig,
    LibControlGuids.TEmailTemplateForm,
    LibControlGuids.TEMailSmtpConfiguration
  ],
  // --- SIDE DEV NAVIGATION ---
  [ProtectedView.OnboardingOverviewComponent]: [
    AppControlGuids.TPayerDataOfficeTable,
    AppControlGuids.TPayerTable,
    AppControlGuids.TItemTable,
    AppControlGuids.TItemgroupTable,
    AppControlGuids.TItemPriceHistoryView,
    AppControlGuids.TBusinessAreaMappingTable,
    AppControlGuids.TItemPriceMapping,
    AppControlGuids.TAlternativePayerView,
    AppControlGuids.TPayerTableView,
    AppControlGuids.TBookingOptionsConfig,
    AppControlGuids.TCustomerBasedataConfiguration,
    AppControlGuids.TDTAServiceConfigView,
    AppControlGuids.TGlobalTemplateConfigForm,
    AppControlGuids.TInvoiceTemplateImportTable,
    AppControlGuids.TTemplateConfigurationControl,
    AppControlGuids.TDunningConfigView,
    AppControlGuids.TDunningLevelView,
    AppControlGuids.TOpenItemImportConfig,
    AppControlGuids.TEpostConfiguration,
  ],
  [ProtectedView.ItemTableComponent]: [AppControlGuids.TItemTable],
  [ProtectedView.ItemGroupBaseTableComponent]: [AppControlGuids.TItemgroupTable],
  [ProtectedView.PayerTableComponent]: [AppControlGuids.TPayerTable],
  [ProtectedView.DataOfficeBaseTableComponent]: [AppControlGuids.TPayerDataOfficeTable],
  [ProtectedView.InvoiceTemplateTableComponent]: [AppControlGuids.TInvoiceTemplateTable],
  [ProtectedView.CollectiveinvoiceTableComponent]: [AppControlGuids.TCollectiveInvoiceTable],
  [ProtectedView.CarttypeTableComponent]: [AppControlGuids.TCartTypeTable],
  [ProtectedView.AddressinfoTableComponent]: [AppControlGuids.TAddressInfoView],
  [ProtectedView.BusinessAreasTableComponent]: [AppControlGuids.TBusinessAreaMappingTable],
  [ProtectedView.ItemPriceMappingTableComponent]: [AppControlGuids.TItemPriceMapping],
  [ProtectedView.InvoiceTemplateConfigFormComponent]: [AppControlGuids.TInvoiceTemplateConfigForm],
  [ProtectedView.GlobalTemplateConfigFormComponent]: [AppControlGuids.TGlobalTemplateConfigForm],
  [ProtectedView.BaseInvoiceTemplateConfigTableComponent]: [AppControlGuids.TInvoiceTemplateImportTable],
  // ----------------------------

  // --- CART DETAIL TABS ------
  [ProtectedView.ConspicuityDetailComponent]: [AppControlGuids.TNotBillable],
  [ProtectedView.ClientDetailComponent]: [AppControlGuids.TClientList],
  [ProtectedView.TransportDetailComponent]: [AppControlGuids.TTransportList],
  [ProtectedView.CrewDetailComponent]: [AppControlGuids.TOrdinanceList, AppControlGuids.TCrewList],
  [ProtectedView.CartAdditionalDetailComponent]: [AppControlGuids.TCrudCartAdditionaldata],
  [ProtectedView.InvoiceDetailComponent]: [AppControlGuids.TInvoiceList, AppControlGuids.TDunningInfo, AppControlGuids.TCartItemsList],
  [ProtectedView.TrapoDetailComponent]: [AppControlGuids.TTrapoList],
  [ProtectedView.InternalNoteDetailComponent]: [AppControlGuids.TInternalNotes],
  [ProtectedView.PermanentOrdinanceComponent]: [AppControlGuids.TPermanentOrdinanceForClient],
  // ----------------------------

  // --- PAYER OVERVIEW TABS ----
  [ProtectedView.PayerHiddenTableComponent]: [AppControlGuids.TPayerTableView],
  [ProtectedView.DataofficeTableComponent]: [AppControlGuids.TDataOfficeTableView],
  [ProtectedView.AlternativePayerTableComponent]: [AppControlGuids.TAlternativePayerView],
  // ----------------------------

  // --- GENERAL SETTINGS TABS -- (App Configuration Overview)
  [ProtectedView.CustomerBasedataConfigFormComponent]: [AppControlGuids.TCustomerBasedataConfiguration],
  [ProtectedView.BookingOptionsConfigFormComponent]: [AppControlGuids.TBookingOptionsConfig],
  [ProtectedView.OpenItemImportConfigFormComponent]: [AppControlGuids.TOpenItemImportConfig],
  [ProtectedView.EpostConfigWrapperComponent]: [AppControlGuids.TEpostConfiguration],
  [ProtectedView.MailTemplateComponent]: [LibControlGuids.TEmailTemplateForm],
  [ProtectedView.SmtpConfigurationComponent]: [LibControlGuids.TEMailSmtpConfiguration],
  [ProtectedView.VerificationRulesConfigFormComponent]: [AppControlGuids.TTestingSettingsConfig],
  // ----------------------------
}
