import {Component, effect, inject, input, model, signal} from '@angular/core';
import {JsonPipe, NgIf} from '@angular/common';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {SelectElementComponent} from '../../form-element/select-element/select-element.component';
import {ControlStore, EControlActions, FormControlService, IApiControl, IControlObject} from 'frontier/nucleus';
import {ISelectFormElement, ISelectFormOption} from '../../form-element/form-data.interface';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'lib-form-repository-selection',
  standalone: true,
  imports: [
    NgIf,
    SelectElementComponent,
    JsonPipe
  ],
  templateUrl: './form-repository-selection.component.html',
  styleUrl: './form-repository-selection.component.css'
})
export class FormRepositorySelectionComponent {
  private _formControlService = inject(FormControlService);
  private _controlStore = inject(ControlStore);

  createNewEnabled = input<boolean>();
  deleteEnabled = input<boolean>();
  deleteAlwaysEnabled = input<boolean>();
  editEnabled  = input<boolean>();
  label = input<string>();

  formApiInstance = input.required<IApiControl | null>();
  formGUID = input.required<string>();

  selectionData = signal<ISelectFormElement>(null)

  selectFormControl = model<FormControl>(null);

  constructor() {
    effect(() => {
      const formApiInstance = this.formApiInstance();
      if (!formApiInstance) {
        return null
      }
      this.updateRepo().subscribe()
    }, {allowSignalWrites: true});
  }

  onNewElement(name: string) {
    this._formControlService.dBObjectFormControlNewElement({
      InstanceId: this.formApiInstance().instanceid,
      formElements: null,
      name
    })
      .subscribe(res => {
        this.updateRepo(EControlActions.create).subscribe(() => {
          this.selectFormControl().patchValue(res);
        })
      })
  }

  deleteElementClicked($event: ISelectFormOption) {
    const dbobject: IControlObject = {
      type: $event.value.type ? $event.value.type : $event.type,
      rowid: $event.value.rowid ? $event.value.rowid : $event.rowid,
      signature: $event.value.signature ? $event.value.signature : $event.signature,
    };
    this._formControlService.dBObjectFormControlDeleteElement(
      {
        InstanceId: this.formApiInstance().instanceid,
        dbobject: dbobject
      })
      .subscribe(() => {
        this.updateRepo(EControlActions.delete).subscribe(() => {
          this.selectFormControl().patchValue(null);
        });
      })
  }

  updateRepo(action?: EControlActions): Observable<any> {
    return this._formControlService.dBObjectFormControlGetRepository(
      {InstanceId: this.formApiInstance().instanceid}
    ).pipe(
      tap((repository: any) => {

        const options: ISelectFormOption[] = repository.options.map((o: any) => {
          const mappedOption: ISelectFormOption = {
            value: o,
            name: o.name,
          }

          return mappedOption;
        });

        // Set value to only possibility in case of dummy repository
        if (options && options.length === 1) {
          this.selectFormControl().patchValue(options[0].value);
        }

        if (action) {
          this._controlStore.controlDataChanged$.emit({GUID: this.formGUID(), changeType: action});
        }
        this.selectionData.set({options})
      })
    );
  }
}
