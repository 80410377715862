import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ColumnApi, FilterModifiedEvent, GridOptions, SortChangedEvent} from 'ag-grid-community';
import {
  ColTypeColDefMap,
  IAgGridData,
  IApiRow,
  IApiTableData,
  ICustomColDef,
  SharedGridOptions,
  TableApiAdapter,
  TColType
} from 'frontier/browserkit';
import {
  CustomBoolCellRendererComponent
} from '../renderers/custom-bool-cell-renderer/custom-bool-cell-renderer.component';
import {AgGridAngular, AgGridModule} from 'ag-grid-angular';
import {concatMap, Observable, switchMap} from 'rxjs';
import {AG_GRID_GERMAN_LOCALE, BaseControlComponent, IApiControl, TableService} from 'frontier/nucleus';
import {map} from 'rxjs/operators';
import {ICustomGridRow} from '../interfaces/custom-grid-row.interface';
import { IColumnFilter } from '../interfaces/column-filter.interface';

@Component({
  selector: 'kpi4me-client-side-table-control',
  templateUrl: './client-side-table-control.component.html',
  styleUrl: './client-side-table-control.component.scss',
  standalone: true,
  imports: [AgGridModule]
})
export class ClientSideTableControlComponent extends BaseControlComponent implements OnInit {
  private _tableService = inject(TableService);

  @ViewChild(AgGridAngular) gridRef: AgGridAngular

  gridOptions: GridOptions<any> = {
    localeText: AG_GRID_GERMAN_LOCALE,
    rowHeight: 30,
    headerHeight: 35,
    loadingCellRenderer: 'loadingRendererComponent',
    loadingOverlayComponent: 'loadingOverlayComponent',
    rowSelection: 'multiple',
    defaultColDef: {
      resizable: true,
      flex: 1,
      suppressMenu: true
    },
    suppressPropertyNamesCheck: true,
    components: {
      ...SharedGridOptions.components,
      customBoolCell: CustomBoolCellRendererComponent
    },
  };

  @Input() override apiInstance: IApiControl;
  @Input() apiAdapter = new TableApiAdapter(
    {},
    {
      ...ColTypeColDefMap,
      [TColType.clBool]: {
        // boolean values
        cellRenderer: 'customBoolCell',
        filter: 'agNumberColumnFilter'
      }
    }
  );

  @Output() cellValueChanged = new EventEmitter();
  columFilterOrSortChange$ = new EventEmitter<SortChangedEvent | FilterModifiedEvent>();

  override get data(): IAgGridData {
    return this._data;
  }

  @Input()
  override set data(d: IApiTableData) {
    this._data = this.apiAdapter.from(d);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subs.add(this.columFilterOrSortChange$.pipe(
      switchMap(() => {
        return this.onSortOrFilterChange()
      })).subscribe()
    );
  }

  fetchRows(): Observable<ICustomGridRow[]> {
    return this._tableService.tablePostFetchRows(
      {
        _parameters: [
          this.apiInstance.instanceid,
          0,
          this.data.rowcount,
          0,
          this.data.displayedColumns.length
        ]
      }
    ).pipe(map((apiRows: object[]) => {
        this.data.rowData = this.apiAdapter.createGridRows(apiRows as IApiRow[]);
        return this.data.rowData;
      })
    )
  }

  private getApiSortModel(
    sortModel: {
      sortIndex: number;
      colId: string | undefined; sort: string | null | undefined
    }[],
    colApi: ColumnApi
  ): {
    sortby?: string[];
    direction?: (0 | 1)[];
  } {
    if (sortModel.length == null) {
      return {};
    }
    const sortby: string[] = [];
    const direction: (0 | 1)[] = [];
    sortModel.filter(sm => sm.sort != null).sort((a, b) => a.sortIndex - b.sortIndex).forEach((sm) => {
      sortby.push((
        (colApi.getColumn(sm.colId).getColDef() as ICustomColDef)
          .attribute +
        ';' +
        (colApi.getColumn(sm.colId).getColDef() as ICustomColDef)
          .attributeindex
      ));
      direction.push(sm.sort == 'asc' ? 0 : 1);
    });
    return {
      sortby,
      direction,
    };
  }

  onSortOrFilterChange() {
    const filterModel = this.gridRef.api.getFilterModel();
    const columnFilter: IColumnFilter[] = [];
    Object.keys(filterModel).forEach((fm) => {
      const colDef = this.gridRef.columnApi
        .getColumn(String(fm))
        .getColDef() as ICustomColDef;
      const apiCol = colDef.apiCol;
      const obj = {
        attribute: apiCol.attribute,
        attributeindex: apiCol.attributeindex,
        expression: filterModel[String(fm)],
      };
      columnFilter.push(obj);
    });

    const sortModel = this.gridRef.columnApi.getColumnState().map((c) => {
      return {colId: c.colId, sort: c.sort, sortIndex: c.sortIndex};
    });
    const apiSortModel = this.getApiSortModel(sortModel, this.gridRef.columnApi);

    console.log('filterModel', filterModel);
    console.log('sortModel', apiSortModel);

    return this._tableService.tablePostChangeFilterAndSorting(
      {
        _parameters: [
          this.apiInstance.instanceid,
          columnFilter,
          apiSortModel
        ]
      }
    )
  }

  // Delayed column filter trigger.
  onColumnFilterChange($event: FilterModifiedEvent) {
    this.columFilterOrSortChange$.emit($event);
  }

  // Delayed column filter trigger.
  onSortChange($event: SortChangedEvent) {
    this.columFilterOrSortChange$.emit($event);
  }

  override changeAndFetch(): Observable<any> {
    return super.changeAndFetch().pipe(
      concatMap(() => {
        return this.fetchRows();
      })
    );
  }

  override setApiInstanceAndFetch(apiInstance: IApiControl): Observable<any> {
    return super.setApiInstanceAndFetch(apiInstance).pipe(
      concatMap(() => {
        return this.fetchRows()
      })
    )
  }
}
