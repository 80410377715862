<div>
  <!--hide selection when there are no data or the repository is only a dummy repository-->
  <!--  <kpi4me-select-element-->
  <!--    #select-->
  <!--    *ngIf="selectionData && (selectionData.options?.length > 1 || deleteInSelectionEnabled || createNewEnabled)"-->
  <!--    [formControlElement]="selectFormControl"-->
  <!--    [data]="selectionData"-->
  <!--    [createNewEnabled]="createNewEnabled"-->
  <!--    (newElementClick)="onNewElement($event)"-->
  <!--    (deleteElementClick)="deleteElementClicked($event)"-->
  <!--    [deleteAlwaysEnabled]="deleteInSelectionAlwaysEnabled"-->
  <!--    [editEnabled]="false"-->
  <!--    [deleteEnabled]="deleteInSelectionEnabled"-->
  <!--  ></kpi4me-select-element>-->
  <lib-form-repository-selection
    [createNewEnabled]="createNewEnabled"
    [editEnabled]="false"
    [deleteAlwaysEnabled]="deleteInSelectionAlwaysEnabled"
    [deleteEnabled]="deleteInSelectionEnabled"
    [formApiInstance]="formWrapperControl().apiInstance()"
    [formGUID]="GUID"
    [label]="selectionLabel"
    [(selectFormControl)]="selectFormControl"
  />
</div>

<lib-form-wrapper-control
  #formWrapperControlComponent
  [inputGUID]="GUID"
  [style.display]="selectFormControl.value != null && visibleFormFields ? '' : 'none'"
  [selectedElement]="selectFormControl.value"
  [apiAdapter]="apiAdapter"
  [displayHeaders]="displayHeaders"
  (instanceInitialized)="formInit.emit($event)"
  (formChange)="formRepositorySelection().updateRepo().subscribe(); cdr.detectChanges(); formChange.emit()"
/>
