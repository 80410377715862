import {Component} from '@angular/core';
import {ApiAdapter} from 'frontier/nucleus';
import {
  DynamicFormControlComponent
} from '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component';
import {IForm} from '../../../components/control/form-control/dynamic-form/form-element/form-data.interface';
import {DynamicFormComponent} from '../../../components/control/form-control/dynamic-form/dynamic-form.component';
import {NgIf} from '@angular/common';
import LibControlGuids from 'frontier/nucleus/src/lib/apiv2/ControlGuids';
import {MatError} from '@angular/material/form-field';

class SMTPApiAdapter extends ApiAdapter {
  labelToTooltipMap = new Map<string, string>([
    ['SMTP-Server', 'z.B.: smtp.office365.com'],
    ['SMTP-Port', 'z.B.: 587'],
    ['SMTP-Benutzer', 'z.B.: service@kpi4me.de'],
    ['SMTP-Password', 'z.B.: Mb2.r5oHf-0t.'],
    ['Sender-Adresse', 'z.B.: service@kpi4me.de'],
    ['Sender-Adresse-Anzeigename', 'z.B.: KPI4me Service'],
    ['Rückantwort-Adresse', 'z.B.: no_reply@kpi4me.de'],
    ['Rückantwort-Adresse-Anzeigename', 'z.B.: NoReply'],
  ]);

  from(apiData: any): any {
    console.log(apiData);
    const updatedElements = apiData.formGroups[0].elements.map((element: any) => {
      if (this.labelToTooltipMap.has(element.label)) {
        return {...element, tooltip: this.labelToTooltipMap.get(element.label)};
      } else {
        return element;
      }
    })
    apiData.formGroups[0].elements = updatedElements;
    return apiData;
  }

  to(data: any): any {
    return null;
  }
}


@Component({
  selector: 'lib-smtp-form-control',
  templateUrl: '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.html',
  styleUrl: '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.scss',
  standalone: true,
  imports: [NgIf, DynamicFormComponent, MatError],
})
export class SmtpFormControlComponent extends DynamicFormControlComponent {
  override apiAdapter = new SMTPApiAdapter();
  override GUID = LibControlGuids.TEMailSmtpConfiguration;

  override toModel(data: IForm) {
    return this.apiAdapter.from(data);
  }

  constructor() {
    super();
  }
}
