import {AfterViewInit, Component, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ICellEditorParams} from 'ag-grid-community';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {TColType} from '../../cell.enum';
import {BaseCell} from '../../interfaces/cell.interface';
import {IControlObject} from 'frontier/nucleus';
import {FormControl} from '@angular/forms';
import {TreeSelectComponent} from '../../../form-control/dynamic-form/form-element/tree-select/tree-select.component';
import {
  ISelectFormOption,
  ITreeSelectFormElement,
  ITreeSelectNode
} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {NgIf} from '@angular/common';
import {TableControlComponent} from 'frontier/browserkit';

interface IReferenceCellEditorParams extends ICellEditorParams {
  emptyOptionAdded: boolean;
  createNewEnabled: boolean;
  editEnabled: boolean;
  deleteEnabled: boolean;
  clearEnabled: boolean;
  recursiveSelection: boolean;
}

@Component({
  selector: 'app-reference-cell-editor',
  templateUrl: './reference-cell-editor.component.html',
  styleUrls: ['./reference-cell-editor.component.scss'],
  standalone: true,
  imports: [NgIf, TreeSelectComponent],
})
export class ReferenceCellEditorComponent extends CustomCellEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild(TreeSelectComponent, {static: false}) treeSelectComponent: TreeSelectComponent;
  private openedAsCellEditor = false;

  selectForm: FormControl = new FormControl<any[]>([]);
  treeSelectData: ITreeSelectFormElement;

  override params: IReferenceCellEditorParams;

  /**
   * Focus the confirm button on tab, instead of jumping to the next cell in the table
   * @param evt
   */
  // @HostListener('keydown.tab', ['$event'])
  // tabHandler(evt: KeyboardEvent) {
  //   // Prevent jumping to the next cell
  //   evt.preventDefault();
  //   evt.stopPropagation();
  //   // focus the button
  //   this.confirmButtonRef._elementRef.nativeElement.focus();
  // }

  // detect clicks outside
  @HostListener('document:click', ['$event'])
  protected override clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!this._elementRef.nativeElement.contains(target)) {
      // this.params.api.stopEditing();
    }
  }

  override agInit(params: IReferenceCellEditorParams) {
    params.colDef = params.colDef as ICustomColDef;
    this.params = params;
    this.oldValue = {...this.params.value};
    let selectionArray = (params.colDef as ICustomColDef).colType === TColType.clReference
      ? [params.value.value]
      : params.value.value.map((i: any) => i);
    selectionArray = selectionArray.filter((v: any) => v != null);
    this.treeSelectData = {
      nodes: [...(params.colDef as ICustomColDef).repository.filter(r => r.signature !== params.value.id)],
      multiple: (params.colDef as ICustomColDef).colType === TColType.clRefSelection,
      arrowPosition: 'right',
      createNewEnabled: params.createNewEnabled,
      deleteElementEnabled: params.deleteEnabled,
      editElementEnabled: params.editEnabled,
      clearEnabled: params.clearEnabled,
      value: selectionArray,
      createRootEnabled: true,
      emptyOptionAdded: params.emptyOptionAdded,
      recursiveSelection: params.recursiveSelection
    };

    super.agInit(params);

    this.openedAsCellEditor = true;

    this._cdr.detectChanges();
  }

  override ngAfterViewInit() {
    if (this.openedAsCellEditor) {
      setTimeout(() => {
        this.treeSelectComponent.inputTrigger.nativeElement.focus();
      });
    }
  }

  override isPopup(): boolean {
    return false;
  }

  override ngOnDestroy() {
    this.treeSelectComponent.isOpen = false;
  }

  override isCancelAfterEnd() {
    return this.canceled || this.oldValue.value == this.params.value.value;
  }


  override getValue(): any {
    return this.params.node.data[this.params.column.getColId()];
  }

  onConfirm() {
    console.log(this.selectForm.value);
    // if (!this.selectForm.value.every((v: { type: string; }) => v.type === (<ICustomColDef>this.params.colDef).apiCol.attributetype)) {
    //   this.feedbackService.setError('Der ausgewählte Knoten kann nicht gesetzt werden. Bitte wählen Sie einen anderen Knoten aus.', 5000);
    //   return;
    // }
    this.params.context.gridApi.stopEditing();
    this.saveValue().subscribe(() => {
      // sets focus into current grid cell
      this.params.context.gridApi.setFocusedCell(
        this.params.rowIndex,
        this.params.colDef.field,
      );
    });
  }

  protected override saveValue() {
    const cell: BaseCell = this.params.value;
    const colDef: any = this.params.colDef;

    return super.changeLine(
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      this.selectForm.value.length === 0 ? null :
        this.selectForm.value.length > 1 ? this.selectForm.value
          : this.selectForm.value[0]
    );
  }

  resetSelection() {
    const colDef: any = this.params.colDef;

    return super.changeLine(
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      null,
    ).subscribe((res) => {
      // sets focus into current grid cell
      this.params.context.gridApi.setFocusedCell(
        this.params.rowIndex,
        this.params.colDef.field
      );
      if (res) {
        this.selectForm.setValue(null);
      }
    });
  }

  protected override setPastedValue(pasted: string) {
    // super.setPastedValue(pasted);
    const colDef: any = this.params.colDef;
    try {
      const object = JSON.parse(pasted);
      super
        .changeLine(
          this.params.data.apiRow.obj,
          this.params.data.apiRow.rowidx,
          colDef.attribute,
          colDef.attributeindex,
          object
        )
        .subscribe(() => this.params.stopEditing());
    } catch (e) {
      console.error('Could not paste copied value because its not json parseable.')
    }

  }

  onNewElementClick(event: string) {
    const colDef: any = this.params.colDef;

    this._tableControlService.tableControlNewSelectionEntry(
      {
        InstanceId: (this.params.context as TableControlComponent).apiInstance().instanceid,
        name: event,
        attributename: colDef.attribute,
        RowObject: this.params.data.apiRow.obj
      }
    ).subscribe((repo: IControlObject[]) => {
        this.treeSelectData = {...this.treeSelectData, nodes: repo as ITreeSelectNode[]};
        (this.params.colDef as ICustomColDef).repository = repo;
        this._cdr.detectChanges();
      }
    )
  }

  onDeleteElement(event: IControlObject) {
    const colDef: any = this.params.colDef;

    this._tableControlService.tableControlDeleteSelectionEntry(
      {
        InstanceId: (this.params.context as TableControlComponent).apiInstance().instanceid,
        attributename: colDef.attribute,
        attribute: event,
        RowObject: this.params.data.apiRow.obj
      }
    ).subscribe((repo) => {
      const node = this.treeSelectComponent.tree.treeModel.getNodeById(event.signature)
      this.treeSelectComponent.deleteNode(node);
      (this.params.colDef as ICustomColDef).repository = repo;
      this._cdr.detectChanges();
    })
  }

  onChangeElement(event: { oldEntry: ISelectFormOption, newName: string }) {
    const colDef: any = this.params.colDef;

    this._tableControlService.tableControlChangeSelectionEntry(
      {
        InstanceId: (this.params.context as TableControlComponent).apiInstance().instanceid,
        name: event.newName,
        attribute: event.oldEntry,
        attributename: colDef.attribute,
        RowObject: this.params.data.apiRow.obj
      }
    ).subscribe((_) => {
      const repo = _ as IControlObject[];
      this.treeSelectData = {...this.treeSelectData, nodes: repo as ITreeSelectNode[]};
      this.selectForm.patchValue([repo.find(o => o.name === event.newName)]);
      (this.params.colDef as ICustomColDef).repository = repo;
    })
  }

  private confirmChange() {
    this.saveValue().subscribe(() => {
      (this.params.context as any).onCellValueChanged(this.params);
      this.params.api.stopEditing();
    });
  }
}
