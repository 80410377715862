<mat-form-field appearance="outline">
  @if (!hideLabel) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    #input
    (ngModelChange)="modelChange.emit($event)"
    *ngIf="inputType !== 'textarea'; else textarea"
    matInput
    [formControl]="getFormControl()"
    [id]="key"
    [type]="inputType === 'number' ? 'text' : inputType"
    [required]="required"
    [step]="step"
  >
  <ng-template #textarea>
        <textarea matInput [formControl]="getFormControl()" [id]="key" [type]="inputType"
                  rows="{{textAreaInitialRowCount}}" (ngModelChange)="modelChange.emit($event)"
                  [required]="required"></textarea>
  </ng-template>

  <mat-error>
    <ng-container *ngFor="let error of this.getErrorMessage()">
      {{ error }} <br>
    </ng-container>
  </mat-error>
</mat-form-field>
