import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {AbstractControl, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IFormElement, ISelectFormElement, ISelectFormOption} from '../form-data.interface';
import {Observable, of, pairwise, startWith, Subscription} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EsvgFiles} from 'frontier/nucleus';

import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {map, take} from 'rxjs/operators';
import {ClearEntityComponent} from 'frontier/browserkit/src/lib/components/clear-entity/clear-entity.component';
import {
  CreateNewEntityComponent
} from 'frontier/browserkit/src/lib/components/create-new-entity/create-new-entity.component';
import {MatTooltip} from '@angular/material/tooltip';
import {MatOption} from '@angular/material/core';
import {
  SearchInputComponent,
  SearchInputComponent as SearchInputComponent_1
} from '../../search-input/search-input.component';
import {MatIcon} from '@angular/material/icon';
import {MatError, MatFormField, MatLabel, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {
  NewEntryDialogComponent
} from 'frontier/browserkit/src/lib/dialogs/basics/new-entry-dialog/new-entry-dialog.component';
import {
  ConfirmationDialogComponent
} from '../../../../../../dialogs/basics/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'kpi4me-select-element',
  templateUrl: './select-element.component.html',
  styleUrls: ['./select-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    MatFormField,
    MatIcon,
    MatSuffix,
    MatPrefix,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    SearchInputComponent_1,
    MatOption,
    MatTooltip,
    MatCheckbox,
    NgFor,
    CreateNewEntityComponent,
    ClearEntityComponent,
    MatError,
    AsyncPipe,
  ],
})

export class SelectElementComponent extends BaseFormElementComponent {
  protected readonly EsvgFiles = EsvgFiles;

  @ViewChild('selectElement') selector: MatSelect;
  searchForm = new FormControl('');
  selectAllChecked = false;
  filteredOptions: Observable<ISelectFormOption[]>;
  @ViewChild(SearchInputComponent, {static: false}) searchInput: SearchInputComponent;

  @Input() createNewEnabled = false;
  @Input() singleStringArray = true;
  @Input() multiple: boolean = null;
  @Input() arrowPosition: 'left' | 'right';
  @Input() newButtonLabel = 'Neues Element';
  @Input() deleteTooltip = 'Eintrag löschen';
  @Input() editTooltip = 'Eintrag bearbeiten';
  @Input() copyTooltip = 'Eintrag duplizieren';
  @Input() deleteEnabled = true;
  @Input() copyEnabled = false;
  @Input() deleteAlwaysEnabled = false;
  @Input() editEnabled = true;
  @Input() clearEnabled = false;
  @Input() disabled = false;
  @Input() confirmButton = false;
  @Input() emptyOptionAdded = false;
  @Input() icon: string;

  @Output() newElementClick = new EventEmitter<string>();
  @Output() deleteElementClick = new EventEmitter<ISelectFormOption>();
  @Output() changeElementClick = new EventEmitter<{ oldEntry: ISelectFormOption, newName: string }>();
  @Output() copyElementClick = new EventEmitter<ISelectFormOption>();
  @Output() confirm = new EventEmitter();
  @Output() clear = new EventEmitter();
  private _options: ISelectFormOption[];
  private _subs = new Subscription();

  @Input() set options(options: ISelectFormOption[]) {
    // Check if options are db objects and if yes map them to selection interface
    if (options && options.length) {
      if (options[0].signature != null) {
        options = options.map(o => ({name: o.name, value: o}))
      }
    }
    // Filter for isactive === false options that are not selected
    options = options.filter(o => !(o.value?.signature != this.formControlElement.value?.signature && o.value.isactive === false));
    this._options = options;

    this.setFilteredOptions(options);
  }

  get options(): ISelectFormOption[] {
    return this._options;
  }

  setFilteredOptions(options: ISelectFormOption[]) {
    this.filteredOptions = of(options);
    if (this.multiple) {
      const notEmptyOptions = options.filter((option) => {
        return option.name;
      });
      this.filteredOptions = of(notEmptyOptions);
      this.updateCheckAllCheckbox();
    } else {
      this.filteredOptions = of(this.options);
    }
  }

  @Input() override set formControlElement(c: AbstractControl) {
    super.formControlElement = c
    /**
     * When an option was selected, check if the old option is inactive and remove it from the options
     */
    if (c) {
      this._subs.add(
        this.formControlElement.valueChanges
          .pipe(
            startWith(this.formControlElement.value),
            pairwise()
          )
          .subscribe(([old, value]) => {
            if (old !== value) {
              const optionOfOld = this._options.find(o => o.value?.signature === old.signature);
              if (optionOfOld.value?.isactive === false) {
                this.deleteOption(optionOfOld);
              }
            }
          })
      )
    }
  };

  override get formControlElement(): FormControl {
    return super.formControlElement;
  }

  @Input()
  override set data(form: ISelectFormElement) {
    if (form == null) return;
    this.filteredOptions = of(form.options);
    this.createNewEnabled = form.createNewEnabled != null ? form.createNewEnabled : this.createNewEnabled;
    this.editEnabled = form.editElementEnabled != null ? form.editElementEnabled : this.editEnabled;
    this.deleteEnabled = form.deleteElementEnabled != null ? form.deleteElementEnabled : this.deleteEnabled;
    this.multiple = form.multiple != null ? form.multiple : false;
    this.arrowPosition = form.arrowPosition;
    this.options = form.options || [];
    this.confirmButton = form.confirmButton;
    this.emptyOptionAdded = form.emptyOptionAdded != null ? form.emptyOptionAdded : this.emptyOptionAdded;
    super.data = form as IFormElement;
  }

  @Input() compareWith = (e1: any, e2: any) => {
    if (e1 !== null && e2 !== null) {
      if (typeof e1 === "object") {
        return e1.signature === e2.signature;
      }
      return e1 === e2 || (e1 === 0 && e2 === 0);
    }
    return false;
  };

  constructor(
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
  ) {
    super();
  }

  onSearchChange(evt: string) {
    this.filteredOptions = of(this.options.filter(o => {
      if (this.multiple) {
        return o.name && o.name.toLowerCase().includes(evt.toLowerCase());
      }
      return o.name.toLowerCase().includes(evt.toLowerCase());
    }));
  }

  focusSearchInput() {
    this.searchInput.focusInput();
  }

  /**
   * Adds a new entry to a dynamic array via a dialog in which the user can name that entry
   * @param categoryLabel: category name to display for user of the dynamic array to which they are about to set a new
   * entry
   */
  createNewOption(categoryLabel?: string): void {
    if (this.createNewEnabled) {
      if (this.singleStringArray) {
        this.dialog.open(
          NewEntryDialogComponent,
          {data: {edit: false, categoryLabel: categoryLabel ? categoryLabel : null}},
        ).afterClosed().subscribe(res => {
          if (res) {
            this.newElementClick.emit(res);
          }
        });
      } else {
        this.newElementClick.emit();
      }
    } else {
      this.newElementClick.emit('');
    }
  }

  /**
   * Deletes an entry from a dynamic array
   * @param evt: click on delete icon
   * @param obj: entry to be deleted from its dynamic array
   */
  delete(evt: MouseEvent, obj: ISelectFormOption): void {
    evt.stopPropagation();
    this.formControlElement.setValue([obj]);
    this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          title: 'Wollen Sie den Eintrag ' + obj.name + ' wirklich löschen?',
          description: 'Die Löschung kann nicht rückgängig gemacht werden.',
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
        }
      }
    ).afterClosed().subscribe(res => {
      if (res) {
        this.deleteElementClick.emit(obj);
      }
    });
  }

  /**
   * Changes an entry in a dynamic array via a dialog in which the user can name that entry
   * @param evt: click on delete icon
   * @param o: the object of the entry to change with its old name
   */
  edit(evt: MouseEvent, o: ISelectFormOption): void {
    evt.stopPropagation();
    this.dialog.open(
      NewEntryDialogComponent,
      {data: {oldValue: o.name}},
    ).afterClosed().subscribe(res => {
      if (res) {
        this.changeElementClick.emit({oldEntry: o, newName: res});
      }
    });
  }

  copy(evt: MouseEvent, o: ISelectFormOption): void {
    evt.stopPropagation();
    this.copyElementClick.emit(o);
  }

  getErrorMessage(): string {
    if (this.formControlElement.hasError('required')) {
      return 'Bitte füllen Sie dieses Feld aus.';
    }
    return '';
  }

  isOptionUnused(o: ISelectFormOption) {
    if (!(o.value)) {
      return false;
    }
    return !o.value.inuse;
  }

  updateInUseValues(evt: MatSelectChange) {
    if (evt.value.signature) {
      this.options = this.options.map((option: ISelectFormOption) => {
        if (evt.value.signature) {
          if (option.value.signature === evt.value.signature) {
            return {...option, value: {...option.value, inuse: true}};
          }
        }
        return option;
      });
      this.filteredOptions = of(this.options);
    }
  }

  clearSelection() {
    this.clear.emit();
  }

  onSelectionChange(evt: any): void {
    if (evt === null || evt === undefined) {
      return;
    }
    this.modelChange.emit(evt.value);
    this.updateCheckAllCheckbox();
  }

  updateCheckAllCheckbox() {
    this.filteredOptions.pipe(take(1), map(options => options.length)).subscribe(l => {
      this.selectAllChecked = this._formControlElement?.value?.length === l;
      this.cdr.detectChanges();
    })
  }

  onSelectAll(evt: MatCheckboxChange): void {
    this.filteredOptions.pipe(
      take(1),
    ).subscribe((options) => {
      console.log(this._formControlElement.value)
      let updatedOptions: ISelectFormOption[];
      if (evt.checked) {
        updatedOptions = options.map((o) => o.value);
      } else {
        updatedOptions = [];
      }
      this._formControlElement.setValue(updatedOptions);
      this.modelChange.emit(updatedOptions);
    })
  }

  // Delete an option from the selectable options
  private deleteOption(option: ISelectFormOption) {
    this._options = this._options.filter(o => o.value !== option.value);
    this.setFilteredOptions(this.options);
  }
}
