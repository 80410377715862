<div
  *ngIf="form"
  [formGroup]="form"
  class="form-wrapper"
>
  @for (fg of formData?.formGroups; track $index) {
    <div class="group-wrapper">
      @if (displayHeaders && actions.length > 0) {
        <div class="mat-h3" style="margin-bottom: 0; display: flex; align-items: center; white-space: pre-line">
          <mat-icon
            *ngIf="fg.closable"
            class="centered"
            style="display: flex; cursor: pointer"
            svgIcon="arrow_left"
            [style.transform]="fg.closed ? 'rotate(180deg)' : 'rotate(-90deg)'"
            (click)="fg.closed = fg.closed == null ? true : !fg.closed"
          />
          <span *ngIf="displayHeaders || (actions.length > 0 && $index === 0)">
          <span *ngIf="displayHeaders">{{ fg.header }}</span>
              @if ($index === 0) {
                @for (action of actions; track action.controlAction) {
                  <lib-dynamic-form-action
                    [action]="action"
                    (callActionFunction)="callActionFunction.emit($event)"
                  />
                }
              }
        </span>
        </div>
      }

      <mat-divider *ngIf="displayHeaders || actions.length > 0" class="header-divider"/>

      @if (standardForm) {
        <div
          [class]=" fg.closed=== true ?'section-wrapper closed' : 'section-wrapper'"
          [style.grid-template-columns]="getTemplateColumns(fg)"
        >
          @for (fc of fg.elements; track $index) {
            <kpi4me-form-element
              [style.grid-row-start]="'auto'"
              style="grid-column-end: -1; grid-column-start: 1"
              (focusOutEvent)="saveValue($event, fg)"
              (requestSave)="saveValue($event, fg)"
              *ngIf="form.controls[fc.key]"
              [formControlElement]="form.controls[fc.key]"
              [formData]="fc"
            ></kpi4me-form-element>
          }
        </div>
      } @else {
        <div
          [class]=" fg.closed === true ?'section-wrapper closed' : 'section-wrapper'"
          [style.grid-template-columns]="getTemplateColumns(fg)"
        >
          <table>
            <tbody style="vertical-align: baseline">
              @if (hasImports && showImports) {
                <tr>
                  <td></td>

                  <td></td>

                  <td class="imported-cell">Importierter Wert</td>
                </tr>
              }
              @for (fc of fg.elements; track $index) {
                <tr>
                  <td class="label-cell">{{ fc.tableLabel }}</td>
                  <td>
                    <kpi4me-form-element
                      [style.grid-row-start]="'auto'"
                      style="grid-column-end: -1; grid-column-start: 1"
                      (focusOutEvent)="saveValue($event, fg)"
                      (requestSave)="saveValue($event, fg)"
                      *ngIf="form.controls[fc.key]"
                      [formControlElement]="form.controls[fc.key]"
                      [formData]="fc"
                    ></kpi4me-form-element>
                  </td>

                  <td
                    class="imported-cell"
                    *ngIf="showImports"
                  >
                    {{ fc.imported }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>
  }

  <button *ngIf="formData?.confirmable" mat-raised-button color="primary" style="max-width: 10rem">Speichern</button>
</div>
