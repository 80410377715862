import {Component, inject, viewChild, ViewEncapsulation} from '@angular/core';
import {FeedbackService} from 'frontier/browserkit';
import {MatButton} from '@angular/material/button';
import {SmtpFormControlComponent} from './smtp-form-control/smtp-form-control.component';
import {EMailSmtpConfigurationService} from 'frontier/nucleus';
import {MatDivider} from '@angular/material/divider';

@Component({
  selector: 'lib-smtp',
  templateUrl: './smtp.component.html',
  styleUrls: ['./smtp.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [SmtpFormControlComponent, MatButton, MatDivider],
})
export class SmtpComponent {
  private _feedBackService = inject(FeedbackService);
  private _smtpConfigService = inject(EMailSmtpConfigurationService);
  form = viewChild(SmtpFormControlComponent);

  testSettings() {
    this._smtpConfigService.eMailSmtpConfigurationSendTestMail({
      InstanceId: this.form().instanceId()
    }).subscribe((res) => {
      this._feedBackService.setNotification(res);
    });
  }
}
