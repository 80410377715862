<div class="flex-column gap-sm">
  <div class="mat-h3 flex-row" style="margin-bottom: 0; display: flex; align-items: center; white-space: pre-line">
    <span> SMTP-Einstellungen </span>
  </div>

  <mat-divider/>

  <lib-smtp-form-control
    [displayHeaders]="false"
  />
  <div class="button-wrapper">
    <button
      [disabled]="!form()?.instanceId()"
      class="kpi4me-button"
      mat-stroked-button
      color="primary"
      (click)="testSettings()">
      Einstellungen Testen
    </button>
  </div>

</div>
